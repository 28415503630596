<template>
  <ScrollTop>
      <a class="btn btn-light btn-toTop">
        <i class="bi bi-caret-up" data-toggle="tooltip" data-placement="bottom" title="Nach oben"></i>
      </a>
  </ScrollTop>
</template>

<script>
import ScrollTop from './ScrollTop'
export default {
  components: {
    ScrollTop
  }
}
</script>

<style>
.btn-toTop {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    color: white;
    font-size: 2rem;
    width: 40px;
    height: 40px;
}
</style>
