<template>
    <div class="row">
        <div class="col-md-12">
            <h2 class="pb-3">Einweisungen {{ getFilterDate }} <button type="button" class="btn btn-primary ml-2 d-print-none" @click.prevent="showDatepicker()"><i class="bi bi-calendar-date"></i> Datum wählen</button></h2>
            <datetime
              input-class="d-none"
              class="datepicker"
              v-model="filter"
              ref="datetime"
              type="date"
              :phrases="{ok: 'OK', cancel: 'Abbrechen'}"
              min-datetime="2021-01-01"
              :max-datetime="maxFilterDate"
              :week-start=1
              format="dd.MM.yyyy"
              auto
              value-zone="Europe/Berlin">
            </datetime>
            <div class="row pb-2">
              <div class="col">
                <div class="card mb-2">
                  <div class="card-body">
                  <h5 class="card-title">Einweisungen:</h5>
                  {{ filteredRows.length }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-2">
                  <div class="card-body">
                  <h5 class="card-title">Teilnehmer:</h5>
                  {{ totalAttendees }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-2">
                  <div class="card-body">
                  <h5 class="card-title">Durchschn. Teilnehmer:</h5>
                  {{ avgAttendees }}
                  </div>
                </div>
              </div>
            </div>

            <table class="table table-striped mt-3">
                <thead>
                    <tr>
                        <th @click.prevent="sortList()" v-if="listSort === 'asc'" style="cursor: pointer" data-toggle="tooltip" data-placement="bottom" title="Klicken, um Sortierung zu ändern">Start <i class="bi bi-sort-down-alt"></i></th>
                        <th @click.prevent="sortList()" v-if="listSort === 'desc'" style="cursor: pointer" data-toggle="tooltip" data-placement="bottom" title="Klicken, um Sortierung zu ändern">Start <i class="bi bi-sort-up"></i></th>
                        <th>Parcours</th>
                        <th>Teilnehmer</th>
                        <th>Trainer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="briefing in filteredRows" :key="briefing.key">
                        <td>{{ briefing.startTime }} Uhr</td>
                        <td>{{ briefing.parcours }}</td>
                        <td>{{ briefing.attendees }}</td>
                        <td>{{ briefing.trainer }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="float-right pt-3" v-if="filteredRows.length > 0">
              <button type="button" class="btn btn-primary ml-2 mb-4 d-print-none" @click.prevent="exportPDF()"><i class="bi bi-file-pdf"></i> Liste als PDF speichern</button>
            </div>
            <div class="alert alert-warning text-center" role="alert" v-if="filteredRows.length < 1">
              Keine Einweisungen am {{ getFilterDate }} gefunden!
            </div>
        </div>
        <ScrollTopArrow></ScrollTopArrow>
    </div>
</template>

<style>
.datepicker .vdatetime-popup__header,
.datepicker .vdatetime-calendar__month__day--selected > span > span,
.datepicker .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #15a34a;
}

.datepicker .vdatetime-calendar__month__day--selected > span > span,
.datepicker .vdatetime-calendar__month__day--selected:hover > span > span,
.datepicker .vdatetime-calendar__month__day:hover > span > span,
.datepicker .vdatetime-calendar__month__day > span > span  {
  border-radius: 2px;
}

.datepicker .vdatetime-year-picker__item--selected,
.datepicker .vdatetime-time-picker__item--selected,
.datepicker .vdatetime-popup__actions__button {
  color: #15a34a;
}

table { page-break-inside:auto }
tr { page-break-inside:avoid; page-break-after:auto }
</style>

<script>
import { db } from '../firebaseDb'
import moment from 'moment-timezone'
// https://github.com/mariomka/vue-datetime
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import ScrollTopArrow from './ScrollTopArrow'
import store from "../store"

export default {
  name: "app",
  components: {
    Datetime,
    ScrollTopArrow
  },
  data () {
    return {
      maxFilterDate: moment(new Date()).format('yyyy-MM-DD'),
      filter: '',
      safetyBriefings: [],
      listSort: 'asc'
    }
  },
  created () {
    this.getData()
  },
  methods: {
  getData () {
    const safetyBriefingsRef = db.collection('safetyBriefings')
    var start
    var end
    if (this.filter) {
      start = new Date(this.filter)
      end = new Date(this.filter)
    } else {
      start = new Date()
      end = new Date()
    }
      start.setHours(0, 0, 0, 0)
      end.setHours(23, 59, 59, 999)
      if (localStorage.sortSettings) {
        this.listSort = localStorage.sortSettings
      }
      safetyBriefingsRef.where('uid', '==', store.getters.user.data.uid).where('StartTime', '>=', start).where('StartTime', '<=', end).orderBy('StartTime').onSnapshot((snapshotChange) => {
      this.safetyBriefings = []
      snapshotChange.forEach((doc) => {
        this.safetyBriefings.push({
          key: doc.id,
          attendees: doc.data().Attendees,
          languageEN: doc.data().LanguageEN,
          parcours: doc.data().Parcours,
          startDateTime: moment(new Date(doc.data().StartTime.seconds * 1000)).format('DD.MM.yyyy HH:mm').toString(),
          startTime: moment(new Date(doc.data().StartTime.seconds * 1000)).format('HH:mm').toString(),
          startTimestamp: doc.data().StartTime.seconds,
          trainer: doc.data().Trainer
        })
      })
        this.SBCount = this.safetyBriefings.length
    })
  },
  showDatepicker () {
      this.$refs.datetime.isOpen = true
    },
  sortList () {
    if (this.listSort === 'asc') {
      this.listSort = 'desc'
      localStorage.sortSettings = 'desc'
    } else {
      this.listSort = 'asc'
      localStorage.sortSettings = 'asc'
    }
  },
  exportPDF () {
    console.log("Export pdf")

    var fileDate
    var headerDate
    if (this.filter) {
      fileDate = moment(new Date(this.filter)).format('yyyy-MM-DD').toString()
      headerDate = moment(new Date(this.filter)).format('DD.MM.yyyy').toString()
    } else {
      fileDate = moment(new Date()).format('yyyy-MM-DD').toString()
      headerDate = moment(new Date()).format('DD.MM.yyyy').toString()
    }
    var fileName = "Einweisungen_" + fileDate

    var pdfMake = require('pdfmake/build/pdfmake.min.js')
      if (pdfMake.vfs === undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
    var ct = []
    ct.push({ text: 'Einweisungen ' + headerDate + '\n\n', style: 'header' })
    ct.push({
      style: 'tableExample',
      table: {
        widths: [100, '*', '*', 200],
        headerRows: 1,
        body: [
          [{ text: 'Startzeit', style: 'tableHeader' }, { text: 'Parcours', style: 'tableHeader' }, { text: 'Teilnehmer', style: 'tableHeader' }, { text: 'Trainer', style: 'tableHeader' }]
        ]
      },
      layout: 'lightHorizontalLines'
    })
    for (var i = 0; i < this.filteredRows.length; i++) {
      ct[1].table.body.push([{ text: this.filteredRows[i].startTime + ' Uhr' }, { text: this.filteredRows[i].parcours }, { text: this.filteredRows[i].attendees }, { text: this.filteredRows[i].trainer }])
    }

    ct.push({ text: '\n\nerstellt am ' + moment(new Date()).format('DD.MM.yyyy [um] HH:mm [Uhr]').toString(), style: 'footer' })
      var docDefinition = {
        content: ct,
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          footer: {
            fontSize: 10,
            italics: true
          }
        }
      }
      pdfMake.createPdf(docDefinition).download(fileName)
  }
  },
  computed: {
    getFilterDate () {
      var searchTerm
      if (this.filter) {
        searchTerm = moment(new Date(this.filter)).format('DD.MM.yyyy').toString()
      } else {
        searchTerm = moment(new Date()).format('DD.MM.yyyy').toString()
      }
      return searchTerm
    },
    filteredRows () {
      var direction = this.listSort
      return this.safetyBriefings.filter(safetyBriefings => {
        const startDateTime = safetyBriefings.startDateTime.toString().toLowerCase()

        return (
          startDateTime.includes(this.getFilterDate))
      }).sort(function (x, y) {
        if (direction === 'asc') {
          return x.startTimestamp - y.startTimestamp
        } else {
          return y.startTimestamp - x.startTimestamp
        }
      })
    },
    totalAttendees: function () {
      let sum = 0
      for (let i = 0; i < this.filteredRows.length; i++) {
        sum += parseFloat(this.filteredRows[i].attendees)
      }

     return sum
   },
   avgAttendees: function () {
      let sum = 0
      for (let i = 0; i < this.filteredRows.length; i++) {
        sum += parseFloat(this.filteredRows[i].attendees)
      }
    if (this.filteredRows.length > 0) {
      return (sum / this.filteredRows.length).toFixed(1).replace(/\./g, ',')
    } else {
      return "-"
    }
   }
},
watch: {
  filter () {
    this.getData()
  }
}
}

</script>
